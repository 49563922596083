<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button
          label="اضافه جديد"
          icon="pi pi-plus"
          class="p-ml-2 p-button-success"
          v-if="$checkRoles('ascorchitAdd')"
          @click="$router.push('ascorchit/add')"
        />
        <Button
          label="حذف"
          icon="pi pi-trash"
          @click="deleteAll"
          v-if="$checkRoles('ascorchitDelete')"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        />
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic p-ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          v-tooltip="'تحميل'"
          @click="exportCSV($event)"
        />
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers"
      :expandedRows.sync="expandedRows"
      :rows="100"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه اسكورشيت

          <div style="text-align:left">
            <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="اختيار الاعمده"
              style="width: 20em"
            />
          </div>
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <Column selectionMode="multiple" field="id" headerStyle="width: 3em" />

      <Column
        v-for="(col, index) of selectedColumns"
        :key="col.field + '_' + index"
        :field="col.field"
        :header="col.header"
        :sortable="true"
      />

      <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button
            type="button"
            @click="$router.push('championship/' + slotProps.data.id)"
            icon="pi pi-cog"
            v-if="$checkRoles('championshipVeiw')"
            class="p-button-success p-ml-2  p-button-rounded"  v-tooltip="'جدول البطوله'"
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            v-if="$checkRoles('ascorchitDelete')"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning  p-button-rounded"
            v-tooltip="'حذف'"
          ></Button>
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="slotProps.data.yearsList"
            class="p-datatable-customers"
          >
            <template #header>
              <div class="table-header">
                المواليد المشاركه
              </div>
            </template>
            <Column field="id" header="المباريات">
              <template #body="slotProps">
                مباره {{ slotProps.index + 1 }}
              </template>
            </Column>
            <Column field="name" header="الاسم" />
          </DataTable>
          <DataTable
            :value="slotProps.data.academsList"
            class="p-datatable-customers"
          >
            <template #header>
              <div class="table-header">
                الاكاديميات المشاركه
              </div>
            </template>
            <Column field="id" header="المباريات">
              <template #body="slotProps">
                مباره {{ slotProps.index + 1 }}
              </template>
            </Column>
            <Column field="team1.userName" header="الاكاديميه 1" />
            <Column field="team2.userName" header="الاكاديميه 2" />
          </DataTable>
          <DataTable
            :value="slotProps.data.teamsList"
            class="p-datatable-customers"
          >
            <template #header>
              <div class="table-header">
                الفرق المشاركه
              </div>
            </template>
            <Column field="id" header="المباريات">
              <template #body="slotProps">
                مباره {{ slotProps.index + 1 }}
              </template>
            </Column>
            <Column field="team1.name" header="الفريق 1" />
            <Column field="team2.name" header="الفريق 2" />

          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expandedRows: [],
      list: [],
      selectedItems: [],
      selectedColumns: [],
      columns: [
        {
          field: 'leaguesId.name',
          header: 'البطوله',
        },
        {
          field: 'conutMatches',
          header: 'عداد المباريات',
        },
        {
          field: 'day',
          header: 'اليوم'
        }
      ],
      loading: true,
    };
  },
  methods: {
    getData() {
      this.$http.get(`ascorchit`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .post(`ascorchit/deleteList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`ascorchit/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
    this.getData();
    this.selectedColumns = this.columns;
  },
};
</script>

<style></style>
